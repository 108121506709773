import React from 'react';
import {marked} from "marked";
import {makeStyles} from '@material-ui/core/styles';
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
    markdown: {
        margin: '0px 20px',
        '& p': {
            ...theme.typography.body1,
            marginBottom: '20px',
        },
        '& p > strong': {
            marginTop: '20px',
            marginBottom: '20px'
        },
        '& h1': theme.typography.h1,
        '& h2': theme.typography.h2,
        '& h3': theme.typography.h3,
        '& h4': theme.typography.h4,
        '& h5': theme.typography.h5,
        '& h6': theme.typography.h6,
    },
}));


const MarkdownRenderer = ({markdownText}) => {
    const classes = useStyles();


    const getMarkdownText = (markdownText) => {
        const rawMarkup = marked(markdownText, {sanitize: true});
        return {__html: rawMarkup};
    };

    return <div className={classes.markdown} dangerouslySetInnerHTML={getMarkdownText(markdownText)}/>;
}

MarkdownRenderer.propTypes = {
    markdownText: PropTypes.string,
};

MarkdownRenderer.defaultProps = {
    markdownText: '',
};

export default MarkdownRenderer;