import React, {useState} from "react";
import PropTypes from "prop-types";
import DateFnsUtils from '@date-io/moment';
import {KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AlarmIcon from '@material-ui/icons/Alarm';
import cn from "classnames";
import moment from "moment";
import numberFormatter from "../../../../managers/numberFormatter";

const useStyles = makeStyles(theme => ({
    timePicker: {
        width: '125px',
        flexBasis: '125px',
        '& p.MuiFormHelperText-root.Mui-error': {
            display: 'none',
        }
    },
    iconButton: {
        padding: "0px",
        marginRight: "-1px"
    },
    none: {
        display: 'none',
    },
    error: {
        color: theme.palette.error.main,
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.error.main
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.error.main
        },
    }
}));

const PlanningTimePicker = ({slug, defaultTime, begin, end, halfDay, onChange, errorChecker}) => {
    const classes = useStyles();
    const now = numberFormatter.getMomentFormat(defaultTime);
    const [time, setTime] = useState(now);
    const check = errorChecker(slug, (now.hour() * 60 + now.minute()));

    const updateTime = (newTime) => {
        if (newTime) {
            const newMinutes = (newTime.hour() * 60 + newTime.minute());
            const isBegin = slug.indexOf('begin') !== -1;
            const nextEnd = isBegin ? end : newMinutes;
            const nextBegin = isBegin ? newMinutes : begin;
            const duration = nextEnd + (nextEnd < nextBegin ? 1440 : 0) - nextBegin;
            onChange(halfDay, nextBegin, duration, isBegin);
        }
    }

    const handleChange = (oldVal, newVal) => {
        const newTime = moment(newVal, 'hh:mm');
        if (oldVal.isValid() && newTime.isValid()) {
            setTime(newTime);
            updateTime(newTime);
        }
    }

    return (
        <>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardTimePicker
                    id={slug}
                    value={time}
                    ampm={false}
                    onChange={handleChange}
                    className={cn(classes.timePicker, !check ? classes.error : '')}
                    inputVariant="outlined"
                    views={["hours", "minutes"]}
                    KeyboardButtonProps={{className: cn(classes.iconButton, !check ? classes.error : '')}}
                    keyboardIcon={<AlarmIcon className={classes.icon}/>}
                    size="small"
                    onAccept={updateTime}
                />
            </MuiPickersUtilsProvider>
        </>
    );
}

PlanningTimePicker.propTypes = {
    slug: PropTypes.string.isRequired,
    halfDay: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    errorChecker: PropTypes.func
};

PlanningTimePicker.defaultProps = {
    errorChecker: () => {
        return false
    }
};

export default PlanningTimePicker;
