import React from "react";
import useEditionStyles from "../../../Simulation/Shared/useEditionStyles";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
  BILLED_NURSING_TIME,
  CONSULTATION,
  CONSUMABLE_MARGIN, CONSUMABLE_SMALL_AREA,
  OTHER_FINANCING
} from "../../../../constants/simulationFields";
import Field from "../../../Simulation/Edition/Field/Field";
import {setValue} from "../../../../actions/defaultValues/defaultValuesAction";
import Paper from "../../../Simulation/Shared/Paper";

const Recipe = () => {
    const editionClasses = useEditionStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {value: consumableCost, error: consumableCostError} = useSelector(state => state.defaultValues?.fields[CONSUMABLE_MARGIN]);
    const {value: consultation, error: consultationError} = useSelector(state => state.defaultValues?.fields[CONSULTATION]);
    const {value: billedNursingTime, error: billedNursingTimeError} = useSelector(state => state.defaultValues?.fields[BILLED_NURSING_TIME]);
    const {value: otherFinnancing, error: otherFinnancingError} = useSelector(state => state.defaultValues?.fields[OTHER_FINANCING]);

    const handleSave = (slug, value) => {
        dispatch(setValue(slug, value));
    }

    return (
        <Paper title={t('simulation.stayRecipe')}>
            <div className={editionClasses.fieldContainer}>
                <Field
                    type="currency"
                    slug={CONSUMABLE_MARGIN}
                    defaultValue={consumableCost}
                    label={t(`simulation.fields.${CONSUMABLE_MARGIN}.label`)}
                    onBlur={handleSave}
                />
            </div>
            <div className={editionClasses.fieldContainer}>
                <Field
                    type="currency"
                    slug={CONSULTATION}
                    defaultValue={consultation}
                    label={t(`simulation.fields.${CONSULTATION}.label`)}
                    onBlur={handleSave}
                />
            </div>
            <div className={editionClasses.fieldContainer}>
                <Field
                    type="currency"
                    slug={BILLED_NURSING_TIME}
                    defaultValue={billedNursingTime}
                    label={t(`simulation.fields.${BILLED_NURSING_TIME}.label`)}
                    onBlur={handleSave}
                />
            </div>
            <div className={editionClasses.fieldContainer}>
                <Field
                    type="currency"
                    slug={OTHER_FINANCING}
                    defaultValue={otherFinnancing}
                    label={t(`simulation.fields.${OTHER_FINANCING}.label`)}
                    onBlur={handleSave}
                />
            </div>
        </Paper>
    );
}

export default Recipe;