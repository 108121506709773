import {
    ANNUAL_CONSUMABLE,
    ANNUAL_STAY,
    CONSUMABLE_PER_DAY,
    DAY_AM_BED,
    DAY_AM_TIME,
    DAY_AM_TIME_BEGIN,
    DAY_PM_BED,
    DAY_PM_TIME,
    DAY_PM_TIME_BEGIN,
    DAYS,
    SERVICE_CONSOMMATION_PAR_ZONE,
    SERVICES
} from "../constants/simulationFields";
import {formatNumber} from "./simulationValuesManager";
import {useSelector} from "react-redux";


export const decodeTime = time => {
    const hour = Math.floor(time);
    const minute = (time - hour) / 60;

    return [hour, minute];
};

export const encodeTime = ([hour, minute]) => {
    return hour + (minute / 60);
};

export const stringToTime = (time) => {
    const split = time.split(':');

    return [parseInt(split[0], 10), parseInt(split[1], 10)];
};

export const percentToString = percent => {
    return `${parseInt(percent * 100, 10)}%`;
}

export const stringToPercent = str => {
    let percent = str.replace(/[^0-9]/g, '');

    return parseInt(percent, 10) / 100;
}

export const checkPainManagement = (stays, consumable, consumablePerStay) => {
    const formatedStays = formatNumber(stays);
    const formatedConsumables = formatNumber(consumable);
    const formatedConsumablePerStay = formatNumber(consumablePerStay, true);
    if (Number.isNaN(formatedStays) || Number.isNaN(formatedConsumables) || Number.isNaN(formatedConsumablePerStay)) {
        return false;
    }
    const diff = (formatedStays * formatedConsumablePerStay) - formatedConsumables;
    return Math.abs(diff) < 1;
}

export const computePainManagement = (slug, stays, consumables, consumablePerStay) => {
    let computedStays = stays;
    let computedConsumables = consumables;
    let computedConsumablePerStay = consumablePerStay;
    if (slug === ANNUAL_STAY) {
        computedStays = computedConsumables / computedConsumablePerStay;
    }
    if (slug === ANNUAL_CONSUMABLE) {
        computedConsumables = computedConsumablePerStay * computedStays;
    }
    if (slug === CONSUMABLE_PER_DAY) {
        computedConsumablePerStay = computedConsumables / computedStays;
    }

    return {
        [ANNUAL_STAY]: formatNumber(computedStays),
        [ANNUAL_CONSUMABLE]: formatNumber(computedConsumables),
        [CONSUMABLE_PER_DAY]: formatNumber(computedConsumablePerStay, true)
    }
}

export const useCheckLitTotal = isBenchmark => {
    const valueProp = !isBenchmark ? 'mainValue' : 'benchmarkValue';
    const lundiamBed = useSelector(state => state.simulation.values[`lundi${DAY_AM_BED}`][valueProp]);
    const lundipmBed = useSelector(state => state.simulation.values[`lundi${DAY_PM_BED}`][valueProp]);
    const mardiamBed = useSelector(state => state.simulation.values[`mardi${DAY_AM_BED}`][valueProp]);
    const mardipmBed = useSelector(state => state.simulation.values[`mardi${DAY_PM_BED}`][valueProp]);
    const mercrediamBed = useSelector(state => state.simulation.values[`mercredi${DAY_AM_BED}`][valueProp]);
    const mercredipmBed = useSelector(state => state.simulation.values[`mercredi${DAY_PM_BED}`][valueProp]);
    const jeudiamBed = useSelector(state => state.simulation.values[`jeudi${DAY_AM_BED}`][valueProp]);
    const jeudipmBed = useSelector(state => state.simulation.values[`jeudi${DAY_PM_BED}`][valueProp]);
    const vendrediamBed = useSelector(state => state.simulation.values[`vendredi${DAY_AM_BED}`][valueProp]);
    const vendredipmBed = useSelector(state => state.simulation.values[`vendredi${DAY_PM_BED}`][valueProp]);
    const samediamBed = useSelector(state => state.simulation.values[`samedi${DAY_AM_BED}`][valueProp]);
    const samedipmBed = useSelector(state => state.simulation.values[`samedi${DAY_PM_BED}`][valueProp]);
    const dimancheamBed = useSelector(state => state.simulation.values[`dimanche${DAY_AM_BED}`][valueProp]);
    const dimanchepmBed = useSelector(state => state.simulation.values[`dimanche${DAY_PM_BED}`][valueProp]);

    return (value) => {
        const formatedValue = formatNumber(value);
        if (Number.isNaN(formatedValue)) return false;

        return (
            lundiamBed <= formatedValue &&
            lundipmBed <= formatedValue &&
            mardiamBed <= formatedValue &&
            mardipmBed <= formatedValue &&
            mercrediamBed <= formatedValue &&
            mercredipmBed <= formatedValue &&
            jeudiamBed <= formatedValue &&
            jeudipmBed <= formatedValue &&
            vendrediamBed <= formatedValue &&
            vendredipmBed <= formatedValue &&
            samediamBed <= formatedValue &&
            samedipmBed <= formatedValue &&
            dimancheamBed <= formatedValue &&
            dimanchepmBed <= formatedValue
        );
    };
}

export const checkBedDay = (bedDay, totalBed) => {
    const formatedValue = formatNumber(bedDay);
    if (Number.isNaN(formatedValue)) return false;

    return formatedValue <= totalBed;
}

export const useGetDuplicateDayValues = isBenchmark => {
    const valueProp = !isBenchmark ? 'mainValue' : 'benchmarkValue';
    const values = useSelector(state => state.simulation.values);

    return day => {
        const amHoraireToDuplicate = values[`${day}${DAY_AM_TIME}`][valueProp];
        const pmHoraireToDuplicate = values[`${day}${DAY_PM_TIME}`][valueProp];
        const amHoraireDebutToDuplicate = values[`${day}${DAY_AM_TIME_BEGIN}`][valueProp];
        const pmHoraireDebutToDuplicate = values[`${day}${DAY_PM_TIME_BEGIN}`][valueProp];
        const amBedToDuplicate = values[`${day}${DAY_AM_BED}`][valueProp];
        const pmBedToDuplicate = values[`${day}${DAY_PM_BED}`][valueProp];
        const jourValues = {};
        DAYS.forEach((currentJour) => {
            const lit = values[`${currentJour}${DAY_AM_BED}`][valueProp];
            if (day !== currentJour && lit !== 0) {
                jourValues[currentJour] = [];
                jourValues[currentJour].push({
                    slug: `${currentJour}${DAY_AM_TIME}`,
                    value: amHoraireToDuplicate,
                    isBenchmark,
                    isFloat: true
                });
                jourValues[currentJour].push({
                    slug: `${currentJour}${DAY_PM_TIME}`,
                    value: pmHoraireToDuplicate,
                    isBenchmark,
                    isFloat: true
                });
                jourValues[currentJour].push({
                    slug: `${currentJour}${DAY_AM_TIME_BEGIN}`,
                    value: amHoraireDebutToDuplicate,
                    isBenchmark,
                    isFloat: true
                });
                jourValues[currentJour].push({
                    slug: `${currentJour}${DAY_PM_TIME_BEGIN}`,
                    value: pmHoraireDebutToDuplicate,
                    isBenchmark,
                    isFloat: true
                });
                jourValues[currentJour].push({
                    slug: `${currentJour}${DAY_AM_BED}`,
                    value: amBedToDuplicate,
                    isBenchmark,
                    isFloat: false
                });
                jourValues[currentJour].push({
                    slug: `${currentJour}${DAY_PM_BED}`,
                    value: pmBedToDuplicate,
                    isBenchmark,
                    isFloat: false
                });
            }
        });

        return jourValues;
    }
}

export const useGetNextConsommationParZoneValues = isBenchmark => {
    const valueProp = !isBenchmark ? 'mainValue' : 'benchmarkValue';
    const values = useSelector(state => state.simulation.values);

    return (prevValue, nextValue) => {
        const nextValues = [];
        SERVICES.forEach(service => {
            const consommationParZone = values[`${service}${SERVICE_CONSOMMATION_PAR_ZONE}`][valueProp];
            if (consommationParZone === prevValue) {
                nextValues.push({
                    slug: `${service}${SERVICE_CONSOMMATION_PAR_ZONE}`,
                    value: nextValue,
                    isBenchmark,
                    isFloat: true
                });
            }
        });

        return nextValues;
    };
}
