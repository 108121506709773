import {STATIC_DATA_FETCH_FULFILLED} from "../../constants/staticData";

export const INITIAL_STATE = {
    id: null,
    idReg: null,
    code: null,
    lab: null,
    dnp: null,
    patients: null,
    geometry: null,
    coeffGhm: 1
};

export default function healthDivisionReducer(state = INITIAL_STATE, {type, payload}) {
    switch (type) {
        case STATIC_DATA_FETCH_FULFILLED : {
            const {id, idReg, code, lab, dnp, patients, geometry, coeffGHM} = payload;
            return {...state, id, idReg, code, lab: JSON.parse(lab)[0], dnp, patients, geometry, coeffGhm: coeffGHM};
        }
    }
    return state;
};