import React, {useEffect}  from "react";
import PropTypes from "prop-types";
import i18n, {defaultLocale, init, setLocale} from "./i18n"
import { withRouter } from "react-router";

const I18nProvider = ({children, location, history}) => {
    const locale = localStorage.getItem('locale') || defaultLocale;
    if(!i18n.isInitialized) {
        init(locale);
    }
    useEffect(() => {
        return history.listen(nextLocation => {
            const nextLocale = localStorage.getItem('locale') || defaultLocale;
            if(i18n.language !== nextLocale) {
                setLocale(nextLocale);
            }
        });
    }, []);

    return children;
};

I18nProvider.propTypes = {
    children: PropTypes.node.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
};

I18nProvider.defaultProps = {

};

export default withRouter(I18nProvider);