import moment from "moment/moment";

class NumberFormatterFactory {

    constructor() {
        if (!NumberFormatterFactory.instance) {
            this.locale = 'fr-FR';
            this.int = this.getFormatter(0);
            this.float = this.getFormatter(2);
            NumberFormatterFactory.instance = this;
        }
        return NumberFormatterFactory.instance;
    }

    getNow() {
        return moment(new Date()).format('DD/MM/YYYY - HH:mm');
    }

    getMomentFormat(defaultTime) {
        const hours = Math.floor(defaultTime / 60);
        const minutes = Math.round((defaultTime / 60 - hours) * 60);
        const now = moment(new Date());
        now.hours(hours);
        now.minutes(minutes);
        return now
    }

    getPercent(value, total, isEvolution = false) {
        if (value === null || total === null) {
            return 'NA';
        }
        if (total === 0) {
            return this.getPriceValue(0, true, isEvolution);
        }

        return this.getPriceValue((value / total) * 100, true, isEvolution);
    }

    getValue(value, isFloat = false, isEvolution = false) {
        if (value === null) {
            return 'NA';
        }
        const hasDecimal = (value - Math.floor(value)) !== 0;
        const formatter = isFloat && hasDecimal || isEvolution
            ? this.float
            : this.int
        ;
        const formattedValue = formatter.format(value);
        return formattedValue.replace(/\s/g, ' ');
    }

    getPriceValue(value, isFloat = false, isEvolution = false) {
        if (value === null) {
            return 'NA';
        }

        const hasDecimal = (value - Math.floor(value)) !== 0;
        const formatter = isFloat && hasDecimal || isEvolution
            ? this.float
            : this.int
        ;
        let val = formatter.format(value);
        if (value < 0) {
            val = formatter.format(value * -1);
            return `-${val}€`;
        }
        return isEvolution && value > 0 ? `+${val}€` : `${val}€`.replace(/\s/g, ' ');
    }

    getFormatter(nbDigits) {
        return new Intl.NumberFormat(this.locale, {
            minimumFractionDigits: nbDigits,
            maximumFractionDigits: nbDigits,
        });
    }
}

const numberFormatter = new NumberFormatterFactory();
export default numberFormatter;
