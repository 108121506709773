import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import PainManagement from "./PainManagement";
import ResourceManagement from "./ResourceManagement";
import StaffManagement from "./StaffManagement";

const useStyles = makeStyles(theme => ({
    row: {
        display: "flex"
    },
    column: {
        flex: 1
    },
}));

const Management = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const pending = useSelector(state => state.defaultValues.fetchPending);

    if (pending) return null;
    return (
        <div className={classes.row}>
            <div className={classes.column}>
                <PainManagement />
            </div>
            <div className={classes.column}>
                <ResourceManagement />
                {/*<StaffManagement />*/}
            </div>
        </div>
    );
}

Management.propTypes = {
};

Management.defaultProps = {
};

export default Management;