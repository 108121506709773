import React from 'react';
import {NumericFormat} from 'react-number-format';

const CurrencyFormat = (props) => {
    return (
        <NumericFormat
            {...props}
            thousandSeparator=' '
            decimalSeparator=','
            decimalScale={2}
        />
    );
};

CurrencyFormat.propTypes = {};

CurrencyFormat.defaultProps = {};

export default CurrencyFormat;