import React from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
    COST_OTHER,
    COST_MEDICAL_CONSULTANT,
    COST_IDE_BAND_4_HOUR,
    COST_IDE_BAND_5_HOUR,
    COST_IDE_BAND_6_HOUR,
    COST_IDE_BAND_7_HOUR, COST_PHYSIO_PODIA, COST_IDE, COST_STRUCTURE,
} from "../../../../constants/simulationFields";
import Field from "../../../Simulation/Edition/Field/Field";
import useEditionStyles from "../../../Simulation/Shared/useEditionStyles";
import {setValue} from "../../../../actions/defaultValues/defaultValuesAction";
import Paper from "../../../Simulation/Shared/Paper";
import makeStyles from "@material-ui/core/styles/makeStyles";
import NurseCosts from "./NurseCosts";
import {Divider} from "@material-ui/core";


const useStyles = makeStyles(theme => ({
    staffCostsRoot: {
    },
    divider: {
        width: 'calc(100% + 80px)',
        marginLeft: '-40px',
        marginTop: '18px',
        marginBottom: '29px',
    }
}));

const StaffCosts = () => {
    const classes = useStyles();
    const editionClasses = useEditionStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {value: costMedicalConsultant, error: costMedicalConsultantError} = useSelector(state => state.defaultValues.fields?.[COST_MEDICAL_CONSULTANT]);
    const {value: costIde, error: costIdeError} = useSelector(state => state.defaultValues.fields?.[COST_IDE]);
    const {value: costStructure, error: costStructureError} = useSelector(state => state.defaultValues.fields?.[COST_STRUCTURE]);
    const {value: costOther, error: costOtherError} = useSelector(state => state.defaultValues.fields?.[COST_OTHER]);
    const errors = {
    };

    const errorChecker = (slug) => {
        return !errors[slug];
    }

    const handleSave = (slug, value) => {
        dispatch(setValue(slug, parseFloat(value.replace('€', '').replaceAll(',', ''))));
    }

    return (
        <Paper title={t('simulation.staffCosts')}>
            <div className={editionClasses.fieldContainer}>
                <Field
                    type="currency"
                    slug={COST_MEDICAL_CONSULTANT}
                    defaultValue={costMedicalConsultant}
                    label={t(`simulation.fields.${COST_MEDICAL_CONSULTANT}.label`)}
                    helper={t(`simulation.fields.${COST_MEDICAL_CONSULTANT}.helper`)}
                    onBlur={handleSave}
                />
            </div>
            <div className={editionClasses.fieldContainer}>
                <Field
                  type="currency"
                  slug={COST_IDE}
                  defaultValue={costIde}
                  label={t(`simulation.fields.${COST_IDE}.label`)}
                  helper={t(`simulation.fields.${COST_IDE}.helper`)}
                  onBlur={handleSave}
                />
            </div>
            <div className={editionClasses.fieldContainer}>
                <Field
                    type="currency"
                    slug={COST_STRUCTURE}
                    defaultValue={costStructure}
                    label={t(`simulation.fields.${COST_STRUCTURE}.label`)}
                    helper={t(`simulation.fields.${COST_STRUCTURE}.helper`)}
                    onBlur={handleSave}
                />
            </div>
            <div className={editionClasses.fieldContainer}>
                <Field
                    type="currency"
                    slug={COST_OTHER}
                    defaultValue={costOther}
                    label={t(`simulation.fields.${COST_OTHER}.label`)}
                    helper={t(`simulation.fields.${COST_OTHER}.helper`)}
                    onBlur={handleSave}
                />
            </div>
        </Paper>
    );
};

StaffCosts.propTypes = {
};

StaffCosts.defaultProps = {
};

export default StaffCosts;