import React, {lazy} from 'react';
import qs from 'qs';
import i18n, {locales, defaultLocale, hasMmultiLanguages} from '../internationalization/i18n';
import {matchPath, Redirect} from 'react-router-dom'
import {
    ERROR_PAGES,
    MAP_PAGE,
    LOGIN_PAGE,
    NOT_FOUND_PAGE,
    HOME_PAGE,
    SIMULATION_PAGE,
    RESULTAT_PAGE,
    MATERIAL_DEMO_PAGE,
    RESET_PASSWORD_PAGE,
    INIT_PASSWORD_PAGE,
    REPORT_PAGE,
} from "../constants/router";

const LoginPage = lazy(() => import('../pages/LoginPage'));
const ResetPasswordPage = lazy(() => import('../pages/ResetPasswordPage'));
const MapPage = lazy(() => import('../pages/MapPage'));
const SimulationPage = lazy(() => import('../pages/SimulationPage'));
const ResultatPage = lazy(() => import('../pages/ResultatPage'));
const ErrorPage = lazy(() => import('../pages/ErrorPage'));
const MaterialDemoPage = lazy(() => import('../pages/MaterialDemoPage'));
const ReportPage = lazy(() => import('../pages/ReportPage'));


export const routesConfig = [
    {name: LOGIN_PAGE, component: <LoginPage />, isPublic: true},
    {name: RESET_PASSWORD_PAGE, component: <ResetPasswordPage type={"reset"} />, isPublic: true},
    {name: INIT_PASSWORD_PAGE, component: <ResetPasswordPage type={"init"} />, isPublic: true},
    {name: MATERIAL_DEMO_PAGE, component: <MaterialDemoPage />, isPublic: true},
    {name: HOME_PAGE, component: null, isPublic: true},
    {name: MAP_PAGE, component: <MapPage />, isPublic: false},
    {name: SIMULATION_PAGE, component: <SimulationPage />, isPublic: false},
    {name: RESULTAT_PAGE, component: <ResultatPage />, isPublic: false},
    {name: REPORT_PAGE, component: <ReportPage />, isPublic: false, withHeaders: false},
];


const routes = [];

if (hasMmultiLanguages()) {
    routes.push({
        name: null,
        path: "/",
        exact: true,
        localize: false,
        component: <Redirect to="/map"/>
    });
}
routesConfig.forEach(({name, component, parent, isPublic, withHeaders=true}) => {
    Object.keys(locales).forEach(locale => {
        const {routing} = locales[locale];
        const pathPrefix = '';
        const localeRouting = routing?.[name];
        if (localeRouting) {
            const path = pathPrefix + localeRouting;
            let parentPath = null;
            const localeParentRouting = routing?.[parent];
            if (localeParentRouting) {
                parentPath = pathPrefix + localeParentRouting;
            }
            routes.push({
                name,
                path,
                exact: true,
                component,
                locale,
                parent: parentPath,
                isPublic,
                withHeaders
            });
        }
    });
});

Object.keys(ERROR_PAGES).map(code => {
    const name = ERROR_PAGES[code];
    routes.push({name, path: `/${code}`, exact: true, component: <ErrorPage code={parseInt(code, 10)} />, isPublic: true});
});
routes.push({name:NOT_FOUND_PAGE, component: <ErrorPage code={404} />, isPublic: true});

export const getDefaultRouteName = () => {
    let name = null;
    routes.forEach(route => {
        if (name === null && route.name !== undefined && route.name !== null) {
            name = route.name;
        }
    });

    return name;
};

export const getRouteFromPath = path => {
    let r = null;
    routes.forEach(route => {
        if (route.path === path) {
            r = route;
        }
    });

    return r;
};

export const getRouteFromPageAndLocale = (page, locale) => {
    let r = null;
    routes.forEach(route => {
        const {name, path} = route;
        if (name === page) {
            r = route;
        }
    });

    return r;
};

export const getParentRoutesFromPage = page => {
    const parentsPath = [];
    const parentRoutes = [];
    routes.forEach(route => {
        const {name, parent} = route;
        if (page === name) {
            parentsPath.push(parent);
        }
    });
    routes.forEach(route => {
        const {path} = route;
        parentsPath.forEach(parentPath => {
            if (parentPath === path) {
                parentRoutes.push(route);
            }
        })
    });

    return parentRoutes;
};

export const getChildRoutesFromPage = page => {
    const childRoutes = [];
    const pageRoutes = [];
    routes.forEach(route => {
        const {name} = route;
        if (page === name) {
            pageRoutes.push(route);
        }
    });
    routes.forEach(route => {
        const {parent} = route;
        pageRoutes.forEach(pageRoute => {
            const {path} = pageRoute;
            if (parent === path) {
                childRoutes.push(route);
            }
        })
    });

    return childRoutes;
};

export const getMatch = pathname => {
    let match = {path: routes[0].path, params: {}};
    for (let i = 0, l = routes.length; i < l; i += 1) {
        const m = matchPath(pathname, routes[i]);
        if (m !== null) {
            match = m;
            break;
        }
    }

    return match;
};

export const getRouteName = pathname => {
    let pageName = '404';
    for (let i = 0, l = routes.length; i < l; i += 1) {
        const m = matchPath(pathname, routes[i]);
        if (m !== null) {
            pageName = routes[i].name;
            break;
        }
    }

    return pageName;
};

export const getQuery = (query, currentQuery = {}) => {
    const nextQuery = {...currentQuery, ...query};
    Object.keys(nextQuery).forEach(queryItemName => {
        if (nextQuery[queryItemName] === null) {
            delete nextQuery[queryItemName];
        }
    });
    let urlQuery = '';
    if (Object.keys(nextQuery).length > 0) {
        urlQuery = qs.stringify(nextQuery, {addQueryPrefix: true});
    }

    return urlQuery;
};

export const getUrlFromPath = (path, params = {}, currentParams = {}) => {
    Object.keys(params).forEach(param => {
        path = path.replace(`:${param}`, params[param]);
    });
    Object.keys(currentParams).forEach(param => {
        path = path.replace(`:${param}`, currentParams[param]);
    });

    if(path.match(/:[^:/]+/)) {
        return false;
    }

    return path;
};

export const getUrl = ({
                           prevPath = null,
                           prevParams = {},
                           prevQuery = {},
                           page = null,
                           params = {},
                           query = {},
                           locale = null,
                           keepParams = true,
                           keepQuery = true
                       }) => {
    const nextLocale = locale === null || locales[locale] === undefined
        ? i18n.language ?? defaultLocale
        : locale
    ;
    const prevRoute = getRouteFromPath(prevPath);
    let nextPage = getDefaultRouteName();
    if (page !== null) {
        nextPage = page;
    } else if (prevRoute !== null) {
        nextPage = prevRoute.name;
    }
    const nextRoute = getRouteFromPageAndLocale(nextPage, nextLocale);
    let url = '/';
    if(nextRoute !== null) {
        url = getUrlFromPath(nextRoute.path, params, keepParams ? prevParams : {});
        if(url === false) {
            console.error(`router getUrl() : You must provide parameters (prevParams or params) for ${nextRoute.path}`)
            url = '/';
        }
    }
    const nextQuery = getQuery(query, keepQuery ? prevQuery : {});

    return url + nextQuery;
};

export default routes;