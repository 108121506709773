import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ContrastedTextButton from "../../Material/ContrastedTextButton";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import DialogCloseButton from "../../Material/theme/DialogCloseButton";
import {Language} from "@material-ui/icons";
import {QUERY_PARAM_TYPE_GEO} from "../../constants/map";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Button from "@material-ui/core/Button";
import {defaultLocale} from "../../internationalization/i18n";

const useStyles = makeStyles(theme => ({
    monProfilDialog: {
        borderRadius: "24px"
    },
    btn: {
        textTransform: 'none'
    },
    row: {
        display: 'flex',
        justifyContent: 'space-around',
        width: '270px !important',
    },
    actionsContainer: {
        marginTop: '30px',
        display: 'flex',
    },
    cancelButton: {
        marginLeft: 'auto',
        marginRight: "8px"
    },
}));

const LanguageManager = () => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [open, setOpen] = useState(false);
    const [locale, setLocale] = useState(localStorage.getItem('locale') || defaultLocale);

    const changeLanguage = ({target}) => {
        setLocale(target.value);
    }

    const handleClick = () => {
        setOpen(true);
    };

    const handleCancel = () => {
        setOpen(false)
    }

    const handleSubmit = () => {
        i18n.changeLanguage(locale)
            .then(() => {
                localStorage.setItem('locale', locale);
                setOpen(false);
            });
    }

    return (
        <>
            <ContrastedTextButton
                startIcon={<Language/>}
                onClick={handleClick}
                size="large"
                className={classes.btn}
            >
                {t('administration.language.selected')}
            </ContrastedTextButton>
            <Dialog
                open={open}
                onClose={handleCancel}
                aria-labelledby="administration-langague"
            >
                <DialogCloseButton handleClose={handleCancel}/>
                <DialogTitle id="administration-language">
                    <Typography variant="h4">
                        {t(`administration.language.${locale}.title`)}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <RadioGroup name={QUERY_PARAM_TYPE_GEO} row={true} className={classes.row}
                                value={locale} onChange={changeLanguage}>
                        <FormControlLabel value="fr" control={<Radio color="primary"/>}
                                          label={t('administration.language.francais')}/>
                        <FormControlLabel value="nl" control={<Radio color="primary"/>}
                                          label={t('administration.language.nederlands')}/>
                    </RadioGroup>
                    <div className={classes.actionsContainer}>
                        <Button
                            className={classes.cancelButton}
                            variant="contained"
                            onClick={handleCancel}
                        >
                            {t(`administration.language.${locale}.cancel`)}
                        </Button>
                        <div className={classes.spaces}/>
                        <Button
                            className={classes.submitButton}
                            type="submit"
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            {t(`administration.language.${locale}.submit`)}
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
}


LanguageManager.propTypes = {};

LanguageManager.defaultProps = {};

export default LanguageManager;