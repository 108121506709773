import React from "react";
import MentionsLegalesContentFr from "./fr/MentionsLegalesContent.md";
import MentionsLegalesContentNL from "./nl/MentionsLegalesContent.md";
import {useTranslation} from "react-i18next";
import MarkdownRenderer from "./MarkdownRenderer";

const MentionsLegales = () => {
    const {i18n} = useTranslation();

    return <MarkdownRenderer
        markdownText={i18n.language === 'fr' ? MentionsLegalesContentFr : MentionsLegalesContentNL}
    />;
}
export default MentionsLegales;