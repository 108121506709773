import {
    PERSPECTIVE_PANEL_COSE,
    PERSPECTIVE_PANEL_OPEN,
    PERSPECTIVE_PANEL_SAVE_VALUE
} from "../../constants/perspective";
import {
    ANNUAL_STAY,
    CONSUMABLE_PER_DAY,
    PERSPECTIVE_CHAMPS,
    TIME_BED_STAY_HOUR,
    TIME_IDE_STAY_HOUR
} from "../../constants/simulationFields";
import perspectiveValuesReducer, {INITIAL_STATE as VALUES_INITIAL_STATE} from "./perspectiveValuesReducer";
import {getAnnualStay, getConsumableStay} from "../../managers/simulationValuesManager";
import {PARAM_OPTIMISATION_PLANNING} from "../../constants/optimisation";


const INITIAL_STATE = {
    isPanelOpen: false,
    values: PERSPECTIVE_CHAMPS.reduce((accu, champ) => ({...accu, [champ]: VALUES_INITIAL_STATE}), {})
};

export default function perspectiveReducer(state = INITIAL_STATE, {type, payload}) {
    switch (type) {
        case PERSPECTIVE_PANEL_OPEN: {
            const {optimisation, values: simulationValues} = payload;
            const values = {...state.values};
            PERSPECTIVE_CHAMPS.forEach(champ => {
                if (champ === ANNUAL_STAY) {
                    values[champ] = perspectiveValuesReducer(values[champ], {
                        type: PERSPECTIVE_PANEL_OPEN,
                        payload: {
                            mainValue: getAnnualStay(simulationValues, null),
                            benchmarkValue: getAnnualStay(
                                simulationValues,
                                optimisation,
                                optimisation ? 'benchmarkValue' : 'mainValue'),
                            isFloat: false,
                        }
                    });
                } else if (champ === CONSUMABLE_PER_DAY) {
                    values[champ] = perspectiveValuesReducer(values[champ], {
                        type: PERSPECTIVE_PANEL_OPEN,
                        payload: {
                            mainValue: getConsumableStay(simulationValues, null),
                            benchmarkValue: getConsumableStay(
                                simulationValues,
                                optimisation,
                                optimisation ? 'benchmarkValue' : 'mainValue'),
                            isFloat: true,
                        }
                    });
                } else if (champ === TIME_IDE_STAY_HOUR) {
                    values[champ] = perspectiveValuesReducer(values[champ], {
                        type: PERSPECTIVE_PANEL_OPEN,
                        payload: {
                            mainValue: simulationValues[champ].mainValue,
                            benchmarkValue: simulationValues[champ][optimisation ? 'benchmarkValue' : 'mainValue'],
                            isFloat: true,

                        }
                    });
                } else if (champ === TIME_BED_STAY_HOUR && optimisation === PARAM_OPTIMISATION_PLANNING) {
                    values[champ] = perspectiveValuesReducer(values[champ], {
                        type: PERSPECTIVE_PANEL_OPEN,
                        payload: {
                            mainValue: simulationValues[champ].mainValue,
                            benchmarkValue: simulationValues[champ][optimisation ? 'benchmarkValue' : 'mainValue'],
                            isFloat: true,
                        }
                    });
                } else {
                    values[champ] = perspectiveValuesReducer(values[champ], {
                        type: PERSPECTIVE_PANEL_OPEN,
                        payload: {
                            mainValue: simulationValues[champ].mainValue,
                            benchmarkValue: simulationValues[champ][optimisation ? 'benchmarkValue' : 'mainValue'],
                            isFloat: true,
                        }
                    });
                }
            });

            return {
                ...state,
                isPanelOpen: true,
                values
            };
        }
        case PERSPECTIVE_PANEL_COSE: {
            return {
                ...state,
                isPanelOpen: false,
            };
        }
        case PERSPECTIVE_PANEL_SAVE_VALUE: {
            const {type} = payload;

            return {
                ...state,
                values: {
                    ...state.values,
                    [type]: perspectiveValuesReducer(state.values[type], {type: PERSPECTIVE_PANEL_SAVE_VALUE, payload})
                }
            }
        }
    }

    return state;
};