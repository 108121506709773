import React, {forwardRef, useRef, useState} from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import MenuItem from "@material-ui/core/MenuItem";
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded';
import {valueBlurFormater, valueChangeFormater} from "./valueFormater";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import {AMOUNT_YEAR} from "../../../../constants/simulationFields";
import CurrencyFormat from "./CurrencyFormat";
import FloatFormat from "./FloatFormat";

const useStyles = makeStyles(theme => ({
    fieldRoot: {
        width: '100%'
    },
    icon: {
        marginRight: '-16px',
    },
    helper: {
        marginBottom: '18px'
    }
}));

let isInputFullSelection = false;

const Field = forwardRef((props, outerRef) => {
    let {
        className,
        type,
        slug,
        defaultValue,
        size,
        helper,
        noHelper,
        label,
        selectOptions,
        onBlur,
        errorChecker,
        onReset,
        tooltip
    } = props;

    const innerRef = useRef();
    const classes = useStyles();
    const [value, setValue] = useState(valueBlurFormater(defaultValue, type));

    const ref = outerRef || innerRef;
    const check = errorChecker(slug, defaultValue);

    const handleChange = event => {
        let rawValue = '';
        if (event.target.hasOwnProperty('value')) {
            rawValue = event.target.value;
        } else {
            rawValue = event.target.attributes['data-value']?.nodeValue;
        }

        if (rawValue === undefined) {
            rawValue = value;
        }
        const newValue = valueChangeFormater(rawValue, type);
        if (isInputFullSelection) {
            setTimeout(() => {
                event.target.selectionStart = 1;
                event.target.selectionEnd = 1;
            }, 10);
        }
        isInputFullSelection = false;
        setValue(newValue);
    }
    const handleBlur = event => {
        const newValue = valueBlurFormater(event.target.value, type);
        if (newValue !== valueBlurFormater(defaultValue, type) || slug === AMOUNT_YEAR) {
            setValue(newValue);
            onBlur(slug, newValue);
        }
    }

    const handleSelectClose = (event) => {
        setTimeout(() => {
            document.activeElement.blur();
        }, 0);
    }

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            setTimeout(() => {
                document.activeElement.blur();
            }, 0);
        }
        if (event.keyCode >= 48 && event.keyCode <= 57) {
            isInputFullSelection = event.target.selectionEnd - event.target.selectionStart === value.length;
        }
    }
    const handleKeyUp = event => {
        if (event.target.value === '%' || event.target.value === ' €' || event.target.value === 'h'
            || event.target.value === ':' || event.target.value === '€') {
            event.target.selectionStart = 0;
            event.target.selectionEnd = 0;
        }
    }

    const handleClickReset = () => {
        if (onReset) {
            onReset(slug);
        }
    };

    const handleMouseDownReset = (event) => {
        event.preventDefault();
    };

    const getInputProps = () => {
        if (onReset) {
            return {
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            onClick={handleClickReset}
                            onMouseDown={handleMouseDownReset}
                            className={classes.icon}
                        >
                            <RefreshRoundedIcon/>
                        </IconButton>
                    </InputAdornment>
                )
            }
        }

        let inputProps = {};
        if (tooltip) {
            inputProps = {
                ...inputProps,
                endAdornment: (
                    <InputAdornment position="end">
                        {(tooltip !== 'ok') ?
                            <Tooltip
                                arrow
                                placement="top"
                                title={(
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: tooltip
                                        }}
                                    />
                                )}
                            >
                                <IconButton className={classes.icon} color="secondary">
                                    <InfoOutlinedIcon/>
                                </IconButton>
                            </Tooltip>
                            :
                            <IconButton className={classes.icon} color="secondary">
                                <InfoOutlinedIcon/>
                            </IconButton>
                        }a
                    </InputAdornment>
                )
            }
        }
        if (type === 'float') {
            inputProps = {
                ...inputProps,
                inputComponent: FloatFormat
            };
        }
        if (type === 'currency') {
            inputProps = {
                ...inputProps,
                inputComponent: CurrencyFormat
            };
        }

        return inputProps;
    }

    return (
        <div className={cn(classes.fieldRoot, className)}>
            {label && (
                <InputLabel
                    htmlFor={slug}
                    error={!check}
                >
                    {label}
                </InputLabel>
            )}
            <TextField
                id={slug}
                inputRef={ref}
                type="text"
                name={slug}
                value={value}
                fullWidth={true}
                size={size}
                variant="outlined"
                select={selectOptions !== null}
                SelectProps={
                    selectOptions !== null ? {onClose: handleSelectClose, IconComponent: ExpandMoreRoundedIcon} : {}
                }
                inputProps={selectOptions === null ? {onKeyDown: handleKeyDown, onKeyUp: handleKeyUp} : {}}
                InputProps={getInputProps()}
                onChange={handleChange}
                onClick={handleChange}
                onBlur={handleBlur}
                error={!check}
            >
                {selectOptions !== null && selectOptions.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
            {!noHelper && (
                <FormHelperText error={!check} className={cn(helper ? classes.helper : '')}>
                    {helper === null ? <span>&nbsp;</span> : helper}
                </FormHelperText>
            )}
        </div>
    );
});

Field.propTypes = {
    className: PropTypes.string,
    type: PropTypes.string,
    slug: PropTypes.string.isRequired,
    defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    size: PropTypes.oneOf(['small', 'medium']),
    helper: PropTypes.string,
    noHelper: PropTypes.bool,
    label: PropTypes.string,
    selectOptions: PropTypes.array,
    onBlur: PropTypes.func,
    errorChecker: PropTypes.func,
    onReset: PropTypes.func,
    tooltip: PropTypes.string
};

Field.defaultProps = {
    className: '',
    type: 'int',
    defaultValue: 0,
    size: 'medium',
    helper: null,
    noHelper: false,
    label: null,
    selectOptions: null,
    onBlur: () => {
    },
    errorChecker: () => true,
    onReset: null,
    tooltip: null,
};

export default Field;