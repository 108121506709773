import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";
import ConsumableZone from "./ConsumableZone";
import StaffCosts from "./StaffCosts";
import Recipe from "./Recipe";
import ConsumableCost from "./ConsumableCost";


const useStyles = makeStyles(theme => ({
    title: {
        textAlign: "center",
        paddingBottom: "30px"
    },
    row: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
    },
    column: {
        flex: 1
    },
}));

const TariffTotalCost = () => {
    const classes = useStyles();
    const {t} = useTranslation();

    return (
        <>
            <Typography variant="h5" className={classes.title} color="secondary">
                {t('simulation.recettesDepenses')}
            </Typography>
            <div className={classes.row}>
                <div className={classes.column}>
                    <Recipe />
                </div>
                <div className={classes.column}>
                    <StaffCosts />
                </div>
            </div>
        </>
    )
}

TariffTotalCost.propTypes = {

};

TariffTotalCost.defaultProps = {

};

export default TariffTotalCost;