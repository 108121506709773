import React, {useState} from "react";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {
    ANNUAL_CONSUMABLE,
    CONSUMABLE_PER_DAY,
    ANNUAL_STAY
} from "../../../../constants/simulationFields";
import TimelineIcon from '@material-ui/icons/Timeline';
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Field from "../../../Simulation/Edition/Field/Field";
import useEditionStyles from "../../../Simulation/Shared/useEditionStyles";
import {setValue} from "../../../../actions/defaultValues/defaultValuesAction";
import {computePainManagement} from "../../../../managers/simulationManager";
import Paper from "../../../Simulation/Shared/Paper";

const useStyles = makeStyles(theme => ({
    errorAction: {
        display: 'block',
        position: 'absolute',
        right: '-10px',
        top: '50%',
        transform: 'translate(100%, -50%)'
    }
}));


const PainManagement = () => {
    const classes = useStyles();
    const editionClasses = useEditionStyles();
    const {t} = useTranslation();
    const {value: stays, error: staysError} = useSelector(state => state.defaultValues.fields?.[ANNUAL_STAY]);
    const {value: consumables, error: consumablesError} = useSelector(state => state.defaultValues.fields?.[ANNUAL_CONSUMABLE]);
    const {value: consumablesPerStay, error: consumablesPerStayError} = useSelector(state => state.defaultValues.fields?.[CONSUMABLE_PER_DAY]);

    const dispatch = useDispatch();
    const [computeKey, setComputeKey] = useState(null);

    const errorChecker = () => {
        return !(staysError || consumablesError || consumablesPerStayError);
    };

    const handleSave = (slug, value) => {
        dispatch(setValue(slug, value));
    }

    const handleComputeValue = slug => {
        const computedValues = computePainManagement(slug, stays, consumables, consumablesPerStay);
        handleSave(slug, computedValues[slug]);
        setComputeKey(computeKey === slug ? null : slug);
    }

    const renderErrorAction = (slug, value) => {
        const check = errorChecker(slug, value);
        if(check) return null;

        return (
            <div className={classes.errorAction}>
                <Button
                    size="small"
                    variant="outlined"
                    onClick={() => handleComputeValue(slug)}
                    endIcon={<TimelineIcon />}
                >
                    {t('simulation.correct')}
                </Button>
            </div>
        );
    };

    return (
        <Paper title={t('simulation.painManagement')}>
            <div className={editionClasses.fieldContainer}>
                <Field
                    key={computeKey === ANNUAL_STAY ? computeKey : undefined}
                    slug={ANNUAL_STAY}
                    defaultValue={stays}
                    onBlur={handleSave}
                    label={t(`simulation.fields.${ANNUAL_STAY}.label`)}
                    errorChecker={errorChecker}
                />
                {renderErrorAction(ANNUAL_STAY, stays)}
            </div>
            <div className={editionClasses.fieldContainer}>
                <Field
                    key={computeKey === ANNUAL_CONSUMABLE ? computeKey : undefined}
                    slug={ANNUAL_CONSUMABLE}
                    defaultValue={consumables}
                    onBlur={handleSave}
                    label={t(`simulation.fields.${ANNUAL_CONSUMABLE}.label`)}
                    errorChecker={errorChecker}
                />
                {renderErrorAction(ANNUAL_CONSUMABLE, consumables)}
            </div>
            <div className={editionClasses.fieldContainer}>
                <Field
                    key={computeKey === CONSUMABLE_PER_DAY ? computeKey : undefined}
                    type="float"
                    slug={CONSUMABLE_PER_DAY}
                    defaultValue={consumablesPerStay}
                    onBlur={handleSave}
                    label={t(`simulation.fields.${CONSUMABLE_PER_DAY}.label`)}
                    errorChecker={errorChecker}
                />
                {renderErrorAction(CONSUMABLE_PER_DAY, consumablesPerStay)}
            </div>
        </Paper>
    );
};

PainManagement.propTypes = {
};

PainManagement.defaultProps = {
};

export default PainManagement;